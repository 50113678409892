li {
    font-size: 1.25em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}


.ant-card .ant-card-head-title {
    font-size: 1.25em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    white-space: break-spaces;
}

.ant-card .ant-card-head {
    padding: 0 0 0 30%;
}



.ant-card:hover .ant-card-head-title {
       color:#CC9A48;
  }


.ant-card-hoverable {
    cursor: inherit;
    transition: box-shadow 0.2s,border-color 0.2s;
}

.ant-card:hover .ant-card-head  {
    border-bottom: 1px solid #CC9A48;
}


.ant-card-bordered:hover  {
    border: 1px solid #CC9A48;
}