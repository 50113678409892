.job-title .job-description .job-duration {
    font-size: 1.25em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

.job-title {
    font-size: 1.75em;
    color: #CC9A48;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

.job-duration {
    color: #dfdfdf;
    margin-bottom: 10px;
}

.ant-timeline .ant-timeline-item-head {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #161616;
    border: 3px solid transparent;
    border-radius: 50%;
    left: -5px;
}