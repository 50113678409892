.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
  color: white;
  font-size: 1.25em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;


} 

div#icon {
  color: #CC9A48;
  font-size: 2em;
  font-weight: 500;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

div.content-container {
  padding-top: 50px;
  padding-bottom: 50px;
  
}

.ant-layout {
  background-color: #161616;
}

h1.ant-typography {
  color: #dfdfdf;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #161616;
}


.ant-anchor-wrapper .ant-anchor .ant-anchor-link-active>.ant-anchor-link-title {
  color: #CC9A48;
}

.ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-ink {

  background-color: #CC9A48;
}

#footer {
  color: white;
  font-size: 1.25em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  background-color: #161616;
  color:#dfdfdf;
  text-align: center;
}

.footer-icon {
  color: #CC9A48;
  font-size: 7em;
  margin-right: "20px",
}



.footer-icon-link:hover .footer-icon {
  color:#826343;
}

/*
Primary
Secondary

*/