
.my-name {
    color: #CC9A48;
}

.avatar-image {
    min-width: 20em;
    min-height: 20em;
    margin-bottom: 5%;
}

.job-title-typed {
    color: #CC9A48;
}
